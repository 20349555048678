<template>
  <div>
    <div>
      <div class="select-none max-w-7xl mx-auto">
        <div class="mx-4">
          <div class="text-2xl text-body-4 mt-12 font-medium">
            <p>Vaše nemovitosti</p>
            <div v-for="property in properties" :key="property.id">
              <div class="grid grid-cols-2">
                <p class="text-body-1 text-3xl pt-2">
                  {{ property.name ? property.name : "Nevybráno" }}
                </p>
                <div class="">
                  <Delete />
                </div>
              </div>
            </div>
          </div>

          <div class="text-2xl text-body-4 mt-12 font-medium">
            <p>Váš účet</p>
            <div class="grid grid-cols-2">
              <p class="text-body-1 text-3xl pt-2">
                {{ user.id ? user.email : "Nevybráno" }}
              </p>
              <div class="">
                <Delete />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getProperties } from "../../services/rest/services";
import Delete from "@/components/svg/Delete.vue";

export default {
  components: {
    Delete,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      properties: [],
    };
  },
  async mounted() {
    this.properties = await getProperties();
    console.log(this.currentProperty, this.properties, this.user);
  },
};
</script>
